import * as React from 'react'
import {graphql} from 'gatsby'
import {ContentfulPageSection, ContentfulPageSectionData} from '../ContentfulPageSection'

export const query = graphql`
  fragment ContentfulPage on ContentfulPage {
    locale: node_locale
    dark
    hubSpotTrackingCode
    title
    source
    path
    description {
      description
    }
    sections {
      ...ContentfulPageSection
    }
  }
`

export interface ContentfulPageData {
  dark?: boolean
  hubSpotTrackingCode: boolean | null
  locale: string
  title: string
  path: string
  source?: 'Blog'
  description: {
    description: string
  }
  sections?: ContentfulPageSectionData[]
}

export interface PageProps {
  data: ContentfulPageData
}

export const ContentfulPage: React.FC<PageProps> = ({data}) => {
  const {sections} = data

  return (
    <>
      {sections?.map(section => (
        <ContentfulPageSection key={section.id} data={section} />
      ))}
    </>
  )
}
