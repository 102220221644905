import * as React from 'react'
import {graphql, Link} from 'gatsby'
import {Picture, PictureData} from '../Picture'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-US'
import {usePageClass} from '../../utils/usePageClass'

const SWE_ONLY_BLOG_POSTS = [
  // HACK: remove once we have a l10n enabled filter of blog posts
  '6uSkGnKspPxD7NvkF63WOa', // Träna hemma med Träning på Recept och Elsa!
  '3sUW7llajabSGUGKVrWXLu', // Hemmaträning!
  '3htQu8gz4Uq0zdVKrzGp3r', // Vetenskapliga livsstilsråd på webben!
  '3kUraJSGaTmMcxc0nybFtj', // Kvalitativa studier fångar framsteg för personer mer RA
]
const locales = {sv, en}

export const query = graphql`
  fragment ContentfulBlog on ContentfulBlogPostConnection {
    edges {
      node {
        contentfulId: contentful_id
        locale: node_locale
        id
        title
        slug
        published
        author
        image {
          ...Picture
        }
      }
    }
  }
`

export interface BlogPostData {
  id: string
  locale: string
  title: string
  slug: string
  author: string
  published: string
  contentfulId: string
  image: PictureData
}

export interface BlogData {
  edges: {
    node: BlogPostData
  }[]
}

export interface BlogProps {
  pagePath: string
  data: BlogData
}

export const Blog: React.FC<BlogProps> = ({data, pagePath}) => {
  usePageClass('is-blog')

  const blogPosts = data.edges
    .map(({node}) => node)
    .filter(blogPost => !!blogPost.published && !!blogPost.image && !!blogPost.slug)
    .sort((a, b) => Date.parse(b.published) - Date.parse(a.published))

  return (
    <section className="section blog fit-content-to-bottom">
      <div className="container is-narrow">
        <div className="columns is-multiline">
          {blogPosts.map(blogPost => {
            const path = [
              blogPost.locale,
              pagePath,
              ...blogPost.published.split('T')[0].split('-'),
              blogPost.slug,
            ].join('/')

            if (blogPost.locale !== 'sv' && SWE_ONLY_BLOG_POSTS.includes(blogPost.contentfulId)) {
              // HACK: remove once we have a l10n enabled filter of blog posts
              return null
            }

            return (
              <Link
                to={`/${path}/`}
                className="column is-half is-text-small mb-4 content has-gray-1-h4"
                key={blogPost.id}>
                <Picture className="image mb-3" data={blogPost.image} alt={blogPost.title} />
                <h4 className="mb-3">{blogPost.title}</h4>
                <small>
                  <time dateTime={blogPost.published}>
                    {format(parseISO(blogPost.published), 'PPP', {
                      locale: locales[blogPost.locale],
                    })}
                  </time>
                </small>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}
