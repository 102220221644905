import * as React from 'react'
import {Helmet} from 'react-helmet'

export interface MetaProps {
  name?: string
  property?: string
  itemprop?: string
  content?: string
}
export interface SEOProps {
  title: string
  siteName: string
  defaultDescription: string
  path: string
  locale: string
  metadata?: MetaProps[]
  alternatePage?: {
    path: string
    locale: string
  }
}

export const SEO: React.FC<SEOProps> = ({
  title,
  siteName,
  defaultDescription,
  locale,
  metadata,
  path,
  alternatePage,
  children,
}) => {
  const href = `https://www.elsa.science${path}`
  const socialImage = `https://www.elsa.science/social-${locale}.png`
  const fullTitle = `${title} - ${siteName}`
  const defaults: MetaProps[] = [
    {name: 'apple-itunes-app', content: 'app-id=1455833439'},
    {name: 'description', content: defaultDescription},
    {name: 'twitter:site', content: '@elsascience'},
    {name: 'twitter:card', content: 'summary_large_image'},
    {name: 'twitter:title', content: fullTitle},
    {name: 'twitter:description', content: defaultDescription},
    {name: 'twitter:image', content: socialImage},
    {property: 'og:locale', content: locale},
    {property: 'og:locale:alternate', content: locale === 'sv' ? 'en' : 'sv'},
    {property: 'og:url', content: href},
    {property: 'og:type', content: 'website'},
    {property: 'og:title', content: fullTitle},
    {property: 'og:description', content: defaultDescription},
    {property: 'og:image', content: socialImage},
    {property: 'og:image:width', content: '1200'},
    {property: 'og:image:height', content: '630'},
    {itemprop: 'name', content: fullTitle},
    {itemprop: 'url', content: href},
    {itemprop: 'description', content: defaultDescription},
    {itemprop: 'thumbnailUrl', content: socialImage},
  ]
  const mergedMetadata = defaults
    .reduce<MetaProps[]>(
      (result, medadatum) => {
        const {name, property} = medadatum
        if (
          result.findIndex(
            item =>
              (item.name && item.name === name) || (item.property && item.property === property)
          ) > -1
        ) {
          return [...result]
        }
        return [...result, medadatum]
      },
      [...metadata]
    )
    .filter(({content}) => !!content)
  return (
    <Helmet title={`${title} - ${siteName}`}>
      <link rel="canonical" href={href} />
      {alternatePage && <link rel="alternate" hrefLang={locale} href={href} />}
      {alternatePage && (
        <link
          rel="alternate"
          hrefLang={alternatePage.locale}
          href={`https://www.elsa.science${alternatePage.path}`}
        />
      )}
      {mergedMetadata.map((attrs, index) => (
        <meta key={index} {...attrs} />
      ))}
      {children}
    </Helmet>
  )
}
