import * as React from 'react'
import {graphql} from 'gatsby'
import cx from 'classnames'
import type {ContentfulCopyData} from '../ContentfulCopy'
import type {ContentfulColumnGroupData} from '../ContentfulColumnGroup'
import type {ContentfulImageGroupData} from '../ContentfulImageGroup'
import type {ContentfulCompositionData} from '../ContentfulComposition'
import type {ContentfulJourneyData} from '../ContentfulJourney'
import type {ContentfulCarouselData} from '../ContentfulCarousel'
import {ContentfulComponentSwitch} from '../ContentfulComponentSwitch'

export const query = graphql`
  fragment ContentfulPageSection on ContentfulPageSection {
    id
    backgroundColor
    fitContentToBottom
    wrapInContainer
    narrow
    art
    components {
      ...ContentfulCopy
      ...ContentfulColumnGroup
      ...ContentfulImageGroup
      ...ContentfulImageWithText
      ...ContentfulComposition
      ...ContentfulJourney
      ...ContentfulCarousel
    }
  }
`

export interface ContentfulPageSectionData {
  id: string
  backgroundColor?: 'white' | 'green' | 'sky' | 'night'
  fitContentToBottom?: boolean
  wrapInContainer?: boolean
  narrow?: boolean
  art?: 'illustration-1' | 'illustration-2' | 'illustration-3'
  components: (
    | ContentfulCopyData
    | ContentfulColumnGroupData
    | ContentfulImageGroupData
    | ContentfulCompositionData
    | ContentfulJourneyData
    | ContentfulCarouselData
  )[]
}

export interface ContentfulPageSectionProps {
  data: ContentfulPageSectionData
}

export const ContentfulPageSection: React.FC<ContentfulPageSectionProps> = ({data}) => {
  const components = data.components
    ?.filter(component => !!component.type)
    .map(component => <ContentfulComponentSwitch key={component.id} data={component} />)

  return (
    <section
      className={cx('section', {
        'fit-content-to-bottom': data.fitContentToBottom,
        'has-background-white': data.backgroundColor === 'white',
        'has-background-green': data.backgroundColor === 'green',
        'has-background-sky': data.backgroundColor === 'sky',
        'has-background-night': data.backgroundColor === 'night',
        'has-illustration-1': data.art === 'illustration-1',
        'has-illustration-2': data.art === 'illustration-2',
        'has-illustration-3': data.art === 'illustration-3',
      })}>
      {data.wrapInContainer ? (
        <div className={cx('container', {'is-narrow': data.narrow})}>{components}</div>
      ) : (
        components
      )}
    </section>
  )
}
