import * as React from 'react'

export const usePageClass = (cssClass: string): void => {
  const [page, setPage] = React.useState<HTMLElement | undefined>()

  React.useEffect(() => {
    if (page) {
      page.classList.add(cssClass)
    }
    return () => {
      if (page) {
        page.classList.remove(cssClass)
      }
    }
  }, [page, cssClass])

  React.useEffect(() => {
    setPage(document.querySelector('#page') as HTMLElement)
  }, [])
}
