import '../styles/main.scss'
import * as React from 'react'
import {PageProps, graphql} from 'gatsby'
import {Blog, BlogData} from '../components/Blog'
import {Layout, SiteConfigData} from '../components/Layout'
import {ContentfulPage, ContentfulPageData} from '../components/ContentfulPage'
import {SEO} from '../components/SEO'

export const query = graphql`
  query Page(
    $pageId: String!
    $locale: String!
    $isBlogIndexPage: Boolean!
    $contentfulId: String!
  ) {
    page: contentfulPage(id: {eq: $pageId}) {
      ...ContentfulPage
    }
    siteConfig: contentfulSiteConfig(
      node_locale: {eq: $locale}
      contentful_id: {eq: "34xwyJJdatr58oSNFH6VjS"}
    ) {
      ...SiteConfig
    }
    blogPosts: allContentfulBlogPost(filter: {node_locale: {eq: $locale}})
      @include(if: $isBlogIndexPage) {
      ...ContentfulBlog
    }
    alternatePage: sitePage(context: {contentfulId: {eq: $contentfulId}, locale: {ne: $locale}}) {
      path
      context {
        locale
      }
    }
  }
`

interface QueryData {
  page: ContentfulPageData
  siteConfig: SiteConfigData
  blogPosts: BlogData
  alternatePage: {path: string; context: {locale: string}}
}

interface PageContext {
  locale: string
  canonicalPath: string
}

const Page: React.FC<PageProps<QueryData, PageContext>> = ({data, pageContext}) => {
  const {canonicalPath} = pageContext
  const {page, siteConfig, blogPosts, alternatePage} = data
  const {dark, hubSpotTrackingCode, source, title} = page
  return (
    <Layout
      data={siteConfig}
      alternatePagePath={alternatePage?.path}
      isDark={!!dark}
      hubSpotTrackingCode={hubSpotTrackingCode}>
      <SEO
        title={title}
        siteName={siteConfig.siteName}
        locale={siteConfig.locale}
        defaultDescription={siteConfig.defaultDescription}
        path={canonicalPath}
        alternatePage={
          alternatePage && {
            path: alternatePage.path,
            locale: alternatePage.context.locale,
          }
        }
        metadata={[
          {name: 'description', content: page.description?.description},
          {name: 'twitter:description', content: page.description?.description},
          {property: 'og:description', content: page.description?.description},
        ]}
      />
      {source === 'Blog' ? (
        <Blog data={blogPosts} pagePath={page.path} />
      ) : (
        <ContentfulPage data={page} />
      )}
    </Layout>
  )
}

export default Page
